import React, { useContext, useState, useEffect } from 'react'
import { navigate } from "gatsby";
import { ArrowLeft16 } from "@carbon/icons-react";
import { HeedContext } from '../gatsby-theme-carbon/components/Layout'
import { Entity, SubmitButtonComponent } from '@parallelpublicworks/entitysync'
import { TextField } from '../components/carbon-entitysync'
import { Button, InlineLoading } from 'carbon-components-react'

const SubmitButton = SubmitButtonComponent(({onClick, disabled, buttonLabel, kind = "primary", ...props}) => {
    return <Button onClick={(e) => onClick(e)} kind={kind} disabled={disabled}>{buttonLabel}</Button>
  });
  

function EditProfile() {

    const [lastChanged, setLastChanged] = useState(null);
    const [locked, setLocked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [invalidName, setInvalidName] = useState(false);

    const heed = useContext(HeedContext);
    
    useEffect(() => {
        if(locked && heed?.user?.attributes?.changed){
            if((typeof lastChanged === 'string' || lastChanged === null) && typeof heed?.user?.attributes?.changed === 'string'){
                if(lastChanged !== heed?.user?.attributes?.changed){
                    setLocked(false)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [heed])

    if(!heed?.user?.id){
        return <></>
    }

    const onChangeName = (value) => {
        value = value.imaginaryTarget ? value?.imaginaryTarget?.value : value?.target?.value || value || '';
        setInvalidName(!value || (value && !value.length))
    }
    
    const onSave = async (e, unsavedChanges, entityData) => {
        if(unsavedChanges){
            if(typeof unsavedChanges.save !== 'undefined' && unsavedChanges.save === false){
                e.preventDefault();
            }else{
                if(invalidName){
                    e.preventDefault();
                }else{
                    if(entityData?.attributes?.changed){
                        setLastChanged(entityData.attributes.changed);
                    }
                    setLocked(true)
                    setLoading(true)
                    setTimeout(function () {
                        navigate('/')
                    }, 690);
                }
            }
        }
    }
    return (
        <div className="application-wrapper edit-profile">
            <div className="bx--row">
                <div id="left-panel" className="bx--col-lg-4">
                    <div>
                        <Button kind="tertiary" onClick={(e) => navigate('/')}>
                            <ArrowLeft16 /> <span>Back to profile</span>
                        </Button>
                    </div>
                </div>
                <div id="right-panel" className="bx--col-lg-8">
                    <div>
                        <div id="application-title" className="padd">
                            <h2>Edit Profile</h2>
                        </div>
                            <Entity key="entity" type="user--user" source={{id: heed.user.id }} componentId="user-entity">
                                <div className="padd txt">
                                    <TextField placeholder={heed?.user?.attributes.field_full_name} id="field_full_name" light field="field_full_name" type="text" onChange={onChangeName} labelText="Full name" invalidText="Please type your Full name" invalid={invalidName} disabled={locked} />
                                    <TextField placeholder={heed?.user?.attributes.mail} id="email" light field="email" type="text" onChange={(e) => {}} labelText="Your email" disabled={true} />
                                </div>
                                <div className="button-group">
                                {loading ? <InlineLoading className='padd' description="  Saving..." status='active' /> : <SubmitButton disabled={locked} buttonLabel="Save" onSubmit={onSave}   /> }
                                </div>
                            </Entity>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProfile
